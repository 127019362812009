import _unicodeTrie from "unicode-trie";
import _classes from "./classes";
import _pairs from "./pairs";
import _classes2 from "./classes.json";
var exports = {};
const UnicodeTrie = _unicodeTrie;
const ref = _classes;
const {
  BK,
  CR,
  LF,
  NL,
  CB,
  BA,
  SP,
  WJ,
  AI,
  AL,
  SA,
  SG,
  XX,
  CJ,
  ID,
  NS,
  characterClasses
} = _classes;
const {
  DI_BRK,
  IN_BRK,
  CI_BRK,
  CP_BRK,
  PR_BRK,
  pairTable
} = _pairs;
const classTrie = new UnicodeTrie(hex2ab(_classes2.buf));

class Break {
  constructor(position, required) {
    this.position = position;
    this.required = required != null ? required : false;
  }

}

exports = class LineBreaker {
  constructor(string) {
    this.string = string;
    this.pos = 0;
    this.lastPos = 0;
    this.curClass = null;
    this.nextClass = null;
  }

  nextCodePoint() {
    const code = this.string.charCodeAt(this.pos++);
    const next = this.string.charCodeAt(this.pos); // If a surrogate pair

    if (55296 <= code && code <= 56319 && 56320 <= next && next <= 57343) {
      this.pos++;
      return (code - 55296) * 1024 + (next - 56320) + 65536;
    }

    return code;
  }

  nextCharClass(first) {
    if (first == null) {
      first = false;
    }

    return mapClass(classTrie.get(this.nextCodePoint()));
  }

  nextBreak() {
    // get the first char if we're at the beginning of the string
    if (this.curClass == null) {
      this.curClass = mapFirst(this.nextCharClass());
    }

    while (this.pos < this.string.length) {
      this.lastPos = this.pos;
      const lastClass = this.nextClass;
      this.nextClass = this.nextCharClass(); // explicit newline

      if (this.curClass === BK || this.curClass === CR && this.nextClass !== LF) {
        this.curClass = mapFirst(mapClass(this.nextClass));
        return new Break(this.lastPos, true);
      } // handle classes not handled by the pair table


      let cur = null;

      switch (this.nextClass) {
        case SP:
          cur = this.curClass;
          break;

        case BK:
        case LF:
        case NL:
          cur = BK;
          break;

        case CR:
          cur = CR;
          break;

        case CB:
          cur = BA;
          break;
      }

      if (cur != null) {
        this.curClass = cur;

        if (this.nextClass === CB) {
          return new Break(this.lastPos);
        }

        continue;
      } // if not handled already, use the pair table


      let shouldBreak = false;

      switch (pairTable[this.curClass][this.nextClass]) {
        case DI_BRK:
          // Direct break
          shouldBreak = true;
          break;

        case IN_BRK:
          // possible indirect break
          shouldBreak = lastClass === SP;
          break;

        case CI_BRK:
          // prohibited for combining marks
          shouldBreak = lastClass === SP;

          if (!shouldBreak) {
            continue;
          }

          break;

        case CP_BRK:
          if (lastClass !== SP) {
            continue;
          }

      }

      this.curClass = this.nextClass;

      if (shouldBreak) {
        return new Break(this.lastPos);
      }
    }

    if (this.pos >= this.string.length) {
      if (this.lastPos < this.string.length) {
        this.lastPos = this.string.length;
        return new Break(this.string.length);
      } else {
        return null;
      }
    }
  }

};

function mapClass(c) {
  switch (c) {
    case AI:
      return AL;

    case SA:
    case SG:
    case XX:
      return AL;

    case CJ:
      return NS;

    default:
      return c;
  }
}

function mapFirst(c) {
  switch (c) {
    case LF:
    case NL:
      return BK;

    case CB:
      return BA;

    case SP:
      return WJ;

    default:
      return c;
  }
}

function hex2ab(hex) {
  const view = new Uint8Array(hex.length / 2);

  for (let i = 0; i < hex.length; i += 2) {
    view[i / 2] = parseInt(hex.substring(i, i + 2), 16);
  }

  return view;
}

export default exports;